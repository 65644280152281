import { createContext } from 'react';
import PropTypes from 'prop-types';
import useAmpAuth, {useAmpAuthType} from '../../hooks/authentication/useAmpAuth';

export const AuthContext = createContext<useAmpAuthType>({
    isAuthenticated: false,
    loading: false,
    user: null,
    idJwtToken: null,
    accessJwtToken: null,
    error: false,
    signIn: () => {},
    signOut: () => {},
    customState: null
});

export const ProvideAuthContext = ({ children }: { children: React.ReactNode }) => {
    const auth = useAmpAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

ProvideAuthContext.propTypes = {
    children: PropTypes.element
};
