import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';

import { VITE_USER_POOL_ID, VITE_USER_POOL_WEB_CLIENT_ID, VITE_AUTH_DOMAIN } from '../config/constants';

const createAmplifyAuth = () => {
    Hub.listen('auth', ({ channel, payload }) => console.debug(`[hub::${channel}::${payload.event}]`, payload));

    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: VITE_USER_POOL_ID,
                userPoolClientId: VITE_USER_POOL_WEB_CLIENT_ID,
                loginWith: {
                    oauth: {
                        domain: VITE_AUTH_DOMAIN,
                        scopes: ['email', 'openid', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
                        redirectSignIn: [document.location.origin],
                        redirectSignOut: [document.location.origin],
                        responseType: 'code',
                        providers: ['Amazon']
                    }
                }
            }
        }
        // Geo: {
        //     LocationService: {
        //         region: VITE_REGION
        //     }
        // }
    });
    console.debug('Auth current config: ', Amplify.getConfig());
};

export default createAmplifyAuth;
