import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// Layouts
const MainLayout = lazy(() => import('components/layouts/mainLayout'));
const SimpleLayout = lazy(() => import('components/layouts/simpleLayout'));
const CreatorProfileLayout = lazy(() => import('components/layouts/creatorProfileLayout'));

// Dashboard
const ViewConfirmed = lazy(() => import('pages/Dashboard/ViewConfirmed'));
const ViewOnboarding = lazy(() => import('pages/Dashboard/ViewOnboarding'));
const ViewDenied = lazy(() => import('pages/Dashboard/ViewDenied'));
const ViewReview = lazy(() => import('pages/Dashboard/ViewReviewable'));
const ViewApplication = lazy(() => import('pages/Dashboard/ViewApplication/viewApplication'));

// Profile
const ProfileOnboarding = lazy(() => import('pages/CreatorProfile/Onboarding'));
const OpeningInterviewTab = lazy(() => import('pages/CreatorProfile/Onboarding/OpeningInterview'));

// Dev
const ViewAllApps = lazy(() => import('pages/Dev/ViewAllApps/viewAllApps'));
const ViewAllAppsUpload = lazy(() => import('pages/Dev/ViewAllAppsUpload/viewAllAppsUpload'));
const ViewAllCreators = lazy(() => import('pages/Dev/ViewAllCreators/viewAllCreators'));
const ViewAllExecutions = lazy(() => import('pages/Dev/ViewAllExecutions/viewAllExecutions'));
const ViewAllOffers = lazy(() => import('pages/Dev/ViewAllOffers/viewAllOffers'));
const ViewAllProjects = lazy(() => import('pages/Dev/ViewAllProjects/viewAllProjects'));
const ViewAllTaskLinks = lazy(() => import('pages/Dev/ViewAllTaskLinks/viewAllTaskLinks'));
const ViewAllTasks = lazy(() => import('pages/Dev/ViewAllTasks/viewAllTasks'));
const ViewAllYtInfo = lazy(() => import('pages/Dev/ViewAllYtInfo/viewAllYtInfo'));
const ViewAllProjectInfo = lazy(() => import('pages/Dev/ViewAllProjectInfo/viewAllProjectInfo'));

const AppRoutes = () => {
    return (
        <Suspense fallback={null}>
            <Routes>
                {/* Dashboard Routes */}
                <Route element={<MainLayout />}>
                    <Route path='/' element={<Navigate to='/review' replace />} />
                    <Route path='/review' element={<ViewReview />} />
                    <Route path='/onboarding' element={<ViewOnboarding />} />
                    <Route path='/confirmed' element={<ViewConfirmed />} />
                    <Route path='/denied' element={<ViewDenied />} />
                </Route>

                {/* Creator Profile Routes */}
                <Route path='/profile' element={<CreatorProfileLayout />}>
                    <Route path='onboarding' element={<ProfileOnboarding />}>
                        <Route path='opening-interview' element={<OpeningInterviewTab />} />
                        <Route path='audit' element={<></>} />
                        <Route path='agreements' element={<></>} />
                        <Route path='background-check' element={<></>} />
                        <Route path='channel-llc' element={<></>} />
                        <Route path='drop-interview' element={<></>} />
                        <Route index element={<Navigate to='opening-interview' replace />} />
                    </Route>
                    <Route path='drop-info' element={<></>} />
                    <Route path='form-c' element={<></>} />
                    <Route path='documents' element={<></>} />
                    <Route index element={<Navigate to='drop-info' replace />} />
                </Route>

                {/* Dev Routes */}
                <Route element={<SimpleLayout />}>
                    <Route path='/allApps' element={<ViewAllApps />} />
                    <Route path='/applications' element={<ViewAllApps />} />
                    <Route path='/application/:appId' element={<ViewApplication />} />
                    <Route path='/applications/upload' element={<ViewAllAppsUpload />} />
                    <Route path='/creators' element={<ViewAllCreators />} />
                    <Route path='/executions' element={<ViewAllExecutions />} />
                    <Route path='/offers' element={<ViewAllOffers />} />
                    <Route path='/projects' element={<ViewAllProjects />} />
                    <Route path='/tasklinks' element={<ViewAllTaskLinks />} />
                    <Route path='/tasks' element={<ViewAllTasks />} />
                    <Route path='/youtubeinfo' element={<ViewAllYtInfo />} />
                    <Route path='/allprojectinfo' element={<ViewAllProjectInfo />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
