import { useContext } from 'react';

import { AuthContext } from '../../services/contexts/AuthContext';

const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within ProvideAuthContext');
    }
    return context;
};

export default useAuthContext;
