import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LicenseInfo } from '@mui/x-license';

import createAmplifyAuth from 'services/utils/amplifyAuth';
import { ProvideAuthContext } from 'services/contexts/AuthContext';
import App from './App.tsx';
import './styles/index.css';

const SnackbarProvider = React.lazy(() =>
    import('hooks/useSnackbar').then(module => ({ default: module.SnackbarProvider }))
);

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE);
createAmplifyAuth();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false // Disable refetching on window focus
        }
    }
});
ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ProvideAuthContext>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <App />
                </Router>
                <SnackbarProvider />
            </QueryClientProvider>
        </ProvideAuthContext>
    </React.StrictMode>
);
