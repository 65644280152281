import { useState, useEffect } from 'react';

export function getLocalStorageValue<T>(key: string, defaultValue: T): T {
    try {
        const storedValue = localStorage.getItem(key);
        return storedValue !== null ? (JSON.parse(storedValue) as T) : defaultValue;
    } catch (error) {
        console.error(`Error reading localStorage key "${key}":`, error);
        return defaultValue;
    }
}

function useLocalStorage<T>(key: string, defaultValue: T): [T, (value: T) => void] {
    const [storedValue, setStoredValue] = useState<T>(defaultValue);

    const setValue = (value: T) => {
        try {
            setStoredValue(value);
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(`Error setting localStorage key "${key}":`, error);
        }
    };

    useEffect(() => {
        try {
            const item = localStorage.getItem(key);
            if (item) {
                setStoredValue(JSON.parse(item));
            } else {
                localStorage.setItem(key, JSON.stringify(defaultValue));
            }
        } catch (error) {
            console.error(`Error reading localStorage key "${key}":`, error);
        }
    }, [key, defaultValue]);

    return [storedValue, setValue];
}

export default useLocalStorage;
