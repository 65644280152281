import React, { useEffect, Suspense, lazy } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import useAuthContext from 'hooks/authentication/useAuthContext';
import useLocalStorage from 'hooks/useLocalStorage.hook';
import AppRoutes from 'services/config/routes';

const LazyAuthResult = lazy(() => import('services/utils/AuthResult'));
const DevNav = lazy(() => import('components/common/DevNav'));
const RedirectToSignin = lazy(() => import('components/common/RedirectToSignIn'));

const App = (): React.ReactElement => {
    const navigate = useNavigate();
    const { loading, isAuthenticated, error, customState } = useAuthContext();
    useLocalStorage('filter_test_projects', true);
    useLocalStorage('show_dev_nav', false);

    useEffect(() => {
        if (customState) {
            navigate(customState, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const loggedOutState = (
        <Suspense fallback={null}>
            <Routes>
                <Route path='/' element={<RedirectToSignin />} />
                <Route path='*' element={<RedirectToSignin />} />
            </Routes>
        </Suspense>
    );

    return (
        <Suspense fallback={null}>
            <StyledEngineProvider injectFirst>
                <LazyAuthResult
                    loading={loading}
                    error={error}
                    isAuthenticated={isAuthenticated}
                    loggedOutState={loggedOutState}
                    fullpage
                >
                    <DevNav />
                    <AppRoutes />
                </LazyAuthResult>
            </StyledEngineProvider>
        </Suspense>
    );
};

export default App;
