export const VITE_NODE_ENV: string = import.meta.env.VITE_MODE ?? 'development';
export const VITE_OSS_API_URI: string = import.meta.env.VITE_OSS_API_URI ?? 'http://localhost:8080';
export const VITE_FUNDING_PORTAL_URI: string =
    import.meta.env.VITE_FUNDING_PORTAL_URI ?? 'https://invest-dev.gigastar.io';
export const SENTRY_KEY: string = import.meta.env.VITE_SENTRY_API ?? '';
export const GTM_TAG: string = import.meta.env.VITE_GTM_TAG ?? '';

export const VITE_REGION: string = import.meta.env.VITE_REGION ?? '';
export const VITE_USER_POOL_ID: string = import.meta.env.VITE_USER_POOL_ID ?? '';
export const VITE_USER_POOL_WEB_CLIENT_ID: string = import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID ?? '';
export const VITE_AUTH_DOMAIN: string = import.meta.env.VITE_AUTH_DOMAIN ?? '';

export const DISCORD_LINK: string = 'https://discord.gg/gigastar';
export const YOUTUBE_LINK: string = 'https://www.youtube.com/@official_gigastar';
export const INSTAGRAM_LINK: string = 'https://www.instagram.com/gigastar.official/';
export const TWITTER_LINK: string = 'https://twitter.com/GigaStar_';
export const MEDIUM_LINK: string = 'https://medium.com/@GigaStar.Official';
export const FB_LINK: string = 'https://www.facebook.com/GigaStar.Official/';
export const LINKEDIN_LINK: string = 'https://www.linkedin.com/company/gigastar';

export const PRIVACY_POLICY_LINK: string = 'https://gigastar.io/privacy-policy';
export const SUPPORT_CENTER_LINK: string = 'https://support.gigastar.io';
export const LEARNING_CENTER_LINK: string = 'https://gigastar.io/learning-center';
export const GS_RESOURCE_LINK: string = 'https://resources.gigastar.io';
export const APPLY_FOR_A_DROP: string = 'https://gigastarmarket.io/apply-for-a-drop';
export const GS_CORPORATE: string = 'https://gigastar.io';
