/* eslint-disable */

import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Hub } from 'aws-amplify/utils';
import {
    signOut as amplifySignOut,
    getCurrentUser,
    fetchAuthSession,
    signInWithRedirect,
    AuthUser,
    JWT
} from 'aws-amplify/auth';

export interface useAmpAuthType {
    isAuthenticated: boolean;
    loading: boolean;
    user: AuthUser | null;
    idJwtToken: JWT | undefined | null;
    accessJwtToken: JWT | undefined | null;
    error: boolean | string;
    signIn: Function;
    signOut: Function;
    customState: string | null;
}

const useAmpAuth = (): useAmpAuthType => {
    const [user, setUser] = useState<AuthUser | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [idJwtToken, setIdJwtToken] = useState<JWT | undefined | null>(null);
    const [accessJwtToken, setAccessJwtToken] = useState<JWT | undefined | null>(null);
    const [error, setError] = useState<boolean | string>(false);
    const [loading, isLoading] = useState<boolean>(false);
    const [customState, setCustomState] = useState<string | null>(null);

    const refreshState = useCallback(() => {
        isLoading(true);

        getCurrentUser()
            .then(user => {
                console.debug(`useAmpAuth getCurrentUser username: ${user.username}`);
                console.debug(`useAmpAuth getCurrentUser userId: ${user.userId}`);
                console.debug(`useAmpAuth getCurrentUser signInDetails: ${user.signInDetails}`);
                setUser(user);
                setIsAuthenticated(true);
                setError(false);
                isLoading(false);
            })
            .catch(err => {
                setUser(null);
                setIsAuthenticated(false);
                if (
                    err === 'not authenticated' ||
                    err === 'The user is not authenticated' ||
                    'UserUnAuthenticatedException'
                ) {
                    console.debug(`useAmpAuth getCurrentUser squashing error: ${JSON.stringify(err)}`);
                    setError(false);
                } else {
                    console.debug(`useAmpAuth getCurrentUser error: ${JSON.stringify(err)}`);
                    setError(JSON.stringify(err));
                }
                isLoading(false);
            });

        fetchAuthSession()
            .then(authSession => {
                // if (authSession?.tokens?.accessToken && authSession?.tokens?.idToken) {
                console.debug(`useAmpAuth fetchAuthSession accessToken: ${authSession?.tokens?.accessToken}`);
                console.debug(`useAmpAuth fetchAuthSession idToken: ${authSession?.tokens?.idToken}`);
                setIdJwtToken(authSession?.tokens?.accessToken);
                setAccessJwtToken(authSession?.tokens?.idToken);
                setError(false);
                isLoading(false);
                // } else {
                //     throw new Error('useAmpAuth No tokens');
                // }
            })
            .catch(err => {
                setUser(null);
                setIsAuthenticated(false);
                if (err === 'not authenticated' || err === 'The user is not authenticated') {
                    console.debug(`useAmpAuth fetchAuthSession squashing error: ${JSON.stringify(err)}`);
                    setError(false);
                } else {
                    console.debug(`useAmpAuth fetchAuthSession error: ${JSON.stringify(err)}`);
                    setError(JSON.stringify(err));
                }
                isLoading(false);
            });
    }, []);

    // Make sure our state is loaded before first render

    useLayoutEffect(() => {
        refreshState();
    }, [refreshState]);

    // Subscribe to auth events

    useEffect(() => {
        const hubAuthListenerCancelToken = Hub.listen('auth', ({ payload }) => {
            // @ts-ignore: data exists on certain events.
            const { event, data } = payload;
            switch (event) {
                case 'signInWithRedirect':
                case 'signInWithRedirect_failure':
                case 'tokenRefresh':
                case 'tokenRefresh_failure':
                case 'customOAuthState':
                    setCustomState(data);
                    break;
                case 'signedIn':
                case 'signedOut':
                //     refreshState();
                //     break;
                default:
                    break;
            }
        });

        return () => {
            // https://docs.amplify.aws/lib/utilities/hub/q/platform/js/#stop-listening

            hubAuthListenerCancelToken();
        };
    }, [refreshState, customState]);

    const signIn = useCallback(() => {
        signInWithRedirect({
            // provider: 'Amazon',
            customState: document.location.pathname
        }).catch(err => {
            setError(err);
        });
    }, []);

    const signOut = useCallback(async () => {
        try {
            // signout from all devices
            await amplifySignOut({ global: true });
        } catch (err) {
            console.error('useAmpAuth signout error:', JSON.stringify(err));
            setError(JSON.stringify(err));
        }
    }, []);

    return {
        isAuthenticated,
        loading,
        user,
        idJwtToken,
        accessJwtToken,
        error,
        signIn,
        signOut,
        customState
    };
};

export default useAmpAuth;
